<template>
  <div class="row">
    <div class="col-md-12">
      <form @submit.prevent="filterResult(null)">
        <div class="row py-3 px-4" id="filter-card">
          <div class="col-lg-12 col-xl-12 px-0">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-lg-9 col-md-6 col-sm-12 my-2 row px-0 mx-0">
                <input-filter
                    :helper-text="$t('general.search')"
                    title="general.search"
                    :placeholder="$t('general.search_filter')"
                    :model.sync="search"
                ></input-filter>
              </div>
              <div class="col-lg-1 col-md-2 my-lg-2 my-sm-0 row mx-0 ">
                <select-filter
                    :values="languages"
                    :model.sync="selectedLanguageId"
                    :title="$t('general.select_language')"
                    :is-inline="false"
                    :multiple="false"
                ></select-filter>
              </div>
              <div
                  class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 d-flex align-items-center justify-content-end m-0 px-0 row"
                  style="margin: 0 !important;">
                <div class="col-12 pr-0">
                  <button @click="filterResult(null)"
                          class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1">{{
                      $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button @click="resetFilters"
                          class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                    {{ $t('general.clear') }}
                  </button>
                </div>
              </div>
              <div
                  class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 d-flex align-items-center justify-content-end m-0 px-0 row"
              >
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-md-12">
      <dashboard-box :title="$t('general.instruction_type')">
        <template v-slot:preview>
          <data-table
              tableVariant="''"
              :hover="false"
              :outlined="false"
              :striped="false"
              :no-border-collapse="true"
              :borderless="true"
              :bordered="false"
              @onClickEdit="onClickEdit"
              @hitBottom="onButtonHit"
              :items="itemsProxy"
              :fields="fields"
              :page.sync="page"
              :perPage.sync="per_page"
          ></data-table>
          <div class="d-flex justify-content-end items-end float-right mt-0 ">
            <div class="w-60px h-60px "
                 @click="$router.push({name: 'management.instruction_type.create', params: {type: 1}})">
              <span v-html="getIconByKey('icons.waybill.waybill_entry', {
                  class: 'w-60px h-60px d-inline-block object-fill cursor-pointer'
                })">
                </span>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapMutations, mapGetters} from 'vuex'
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import baseModule, {
  BASE_URL,
  ERROR,
  SET_ITEMS,
  ITEMS,
  LOADING,
  MODULE_NAME,
  SUCCESS,
  HANDLE_INFINITE_SCROLL,
} from "@/core/services/store/instruction-type/instructionType.module";

import {LARAVEL_DATE_FORMAT, LARAVEL_DATE_TIME_FORMAT, MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import store from "@/core/services/store";

const _MODULE_NAME = MODULE_NAME;


export default {
  name: "ExpenseIndex",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }

    registerStoreModule(_MODULE_NAME, baseModule)
  },
  components: {
    CustomMultiSelect,
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    DateFilter
  },
  computed: {
    error() {
      return store.getters[_MODULE_NAME + '/' + ERROR];
    },
    loading() {
      return store.getters[_MODULE_NAME + '/' + LOADING];
    },
    success() {
      return store.getters[_MODULE_NAME + '/' + SUCCESS];
    },
    items() {
      return store.getters[_MODULE_NAME + '/' + ITEMS];
    },
    itemsProxy: {
      get() {
        let results = [];
        let items = this.items;

        if (items && items.data) {
          items.data.forEach(item => {
            results.push({
              id: item.id,
              name: item.translations[0].name,
              isActive: +item.status === 1,
              createdAt: (item.created_at) ? moment(item.created_at, LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT) : '',
            })
          })
        }
        return results
      },
      set(value) {},
    },
    filters() {
      return {
        per_page: this.per_page,
        page: this.page,
        search: this.search,
        type: 1
      }
    }
  },
  data() {
    return {
      search: '',
      page: 1,
      per_page: 25,
      fields: [
        {
          key: 'id',
          label: this.$t('general.id'),
          sortable: true,
          class: 'text-center border border-top-0 border-right-0 font-size-lg '
        },
        {
          key: 'name',
          label: this.$t('general.name'),
          sortable: true,
          class: 'text-center border border-top-0 border-right-0 font-size-lg w-50'
        },
        {
          key: 'only_update',
          label: this.$t('general.actions'),
          sortable: false,
          class: 'text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg w-25'
        },
      ],
      selectedLanguageId: null,
      languages: []
    };
  },
  methods: {
    ...mapMutations({
      setItems: SET_ITEMS
    }),
    onButtonHit($state) {
      if (this.loading) {
        return;
      }
      this.state = $state;
      this.$store.dispatch(_MODULE_NAME + '/' + HANDLE_INFINITE_SCROLL, {
        filters: this.filters,
        url: BASE_URL,
        '$state': $state,
      }).then(result => {
        if (result.status && $state) {
          $state.loaded();
        } else {
          if ('end' in result && $state) {
            $state.complete();
          }
        }
      })
    },
    filterResult() {
      this.page = 1;
      this.filters.page = 1;
      let state = this.state;
      this.onButtonHit();
      setTimeout(() => {
        if (state) {
          state.reset();
        }
      }, 100)
    },
    resetFilters() {
      this.search = '';
    },
    onClickEdit(id) {
      this.$router.push({name: 'management.instruction_type.edit', params: {id: id}})
    }
  },
  created() {
    this.setItems({
      id: null,
      name: null,
    })
  },
  mounted() {
    this.showSuccessNotification();
    this.showErrorNotification();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("general.dashboard"), route: "dashboard"},
      {title: this.$t("menu.management.instruction_types")}
    ]);

    this.$store.dispatch(GET_ITEMS, {url: this.url})

    this.languages = this.convertArrayToObjectByKey((this.systemLanguages), 'id', 'name');
  }
}
</script>

<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}
</style>
